import "@popperjs/core/dist/umd/popper.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "swiper/css"
import "swiper/css/bundle"
import { Footer } from "./src/components/footer"
import { Header } from "./src/components/header"
import "./src/fonts/stylesheet.css"
import "./src/styles/global.css"
import {CookieNotice} from "gatsby-cookie-notice";
import { ChakraProvider } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import React from "react"

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Header />
        <CookieNotice
  personalizeButtonClasses={".btn-cookie"}
  personalizeButtonText={"Eu quero personalizar meus cookies!"}
  declineButtonText={"Recusar"}
  acceptButtonText={"Aceitar"}
  personalizeValidationText={"Salvar"}
  cookies={[
    {name: 'necessary', editable: false, default: true, title: 'Necessários', text: 'Os cookies essenciais são necessários para o funcionamento adequado do site. O site não pode funcionar corretamente sem eles.' },
     {name: 'gatsby-gdpr-hotjar', editable: true, default: true, title: 'Hotjar', text: 'Hotjar é uma ferramenta de análise que ajuda a entender e melhorar a experiência do usuário no site.' },
    {name: 'gatsby-gdpr-google-tagmanager', editable: true, default: true, title: 'Google Tag Manager', text: 'O Google Tag Manager é uma ferramenta que permite gerenciar e implantar tags de marketing no seu site sem modificar o código.' },
]}>
 
<h4>Este site utiliza cookies.</h4>
<p>Utilizamos cookies para melhorar o funcionamento do site, mas também para ver como você interage com ele. Só usaremos cookies se você nos permitir, clicando em "Aceitar Cookies". Você também pode escolher quais cookies deseja permitir.</p>
</CookieNotice>
        {element}
        <Footer />
      </ChakraProvider>
    </QueryClientProvider>
  )
}

export const shouldUpdateScroll = () => {
  window.setTimeout(() => window.scrollTo(0, 0), 100)
}