export const productCategories = [
  {
    id: 1,
    name: "Iogurtes",
    value: "iogurte Lacfree, iogurte natural whey, Iogurte Desnatado 2 Ingredientes LACFREE, Iogurte Natural Desnatado 2 Ingredientes LACFREE"
  },
  {
    id: 12,
    name: "Probióticos",
    value: "kefir, probiotico"
  },
  {
    id: 4,
    name: "Shakes",
    value: "shake"
  },
  {
    id: 2,
    name: "Queijos",
    value: "queijo minas, queijo mussarela, queijo frescal",
  },
  {
    id: 14,
    name: "Pastas",
    value: "requeijao, cottage"
  },
  {
    id: 5,
    name: "Cremes",
    value: "creme"
  },
  {
    id: 10,
    name: "Sobremesas",
    value: "sobremesa"
  },

];